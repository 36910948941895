import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
// import Title from './Title';

const axios = require('axios');
const querystring = require('querystring');

const useStyles = makeStyles((theme) => ({
  table: { marginTop: theme.spacing(3) },
  cell: { minWidth: 200 },
  oneline: {
    textOverflow: `ellipsis`,
    overflow: `hidden`,
    whiteSpace: `nowrap`
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function News({ source, rows, setRow, onReload }) {
  const classes = useStyles();
  const [indexSelected, setIndexSelected] = useState(null);
  const editRow = (row, index) => () => {
    setRow(row);
    setIndexSelected(index);
  };
  const publish = (row) => () => {
    const options = { action: `publish`, source, type: `news`, uid: row.uid, state: true };
    const request = axios.post(`https://you.dj/beatport/beatcms.php`, querystring.stringify(options), { headers: { 'content-type': 'application/x-www-form-urlencoded; charset=utf-8' } });
    request.then( response => {
      onReload();
    } ).catch( e => { console.log(e) });
  }
  return (
    <React.Fragment>
      { /* <Title>Last Recent Ads</Title> */ }
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Last Modified</TableCell>
            <TableCell>Content</TableCell>
            <TableCell>Publish</TableCell>
            {/* <TableCell align="right">Preview / Edit</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={row.uid} selected={indexSelected === i} onClick={editRow(row, i)}>
              <TableCell className={classes.cell}>{row.date}</TableCell>
              <TableCell>{row.data.content}</TableCell>
              <TableCell>{row.publish ? `YES` : (
                <Button variant="contained" className={classes.button} color="secondary" onClick={publish(row)}>Ready</Button>
              )}</TableCell>
              {/* <TableCell align="right" ></TableCell> */ }
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/*
      <div className={classes.seeMore}>
        <Link color="primary" href="#" onClick={preventDefault}>
          See more orders
        </Link>
      </div>
      */}
    </React.Fragment>
  );
}

News.propTypes = {
  source: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  setRow: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired
}
