import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import './main.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';

import Ads from './Ads';
import News from './News';
import Tracking from './Tracking';
import AdEdit from './AdEdit';
import NewsEdit from './NewsEdit';
import NewsAdd from './NewsAdd';
import PreviewAd from './PreviewAd';

import useGet from './hooks/useGet';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    position: `absolute`,
    right: 26,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 540,
  },
  editAd: {
    marginTop: 16
  },
  buttonNew: {
    position: `absolute`,
    right: 0
  }
}));

export default function Dashboard() {
  const classes = useStyles();
  const [open] = useState(false);

  const [project, setProject] = useState(`beatport`);
  const changeProject = (type) => () => {
    setProject(type);
    handleTab(null, 0);
  }

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [ads, reloadAds] = useGet(`https://you.dj/beatport/beatcms.php`, {action: `get`, source: project, type: `ads`}, project);
  const [news, reloadNews] = useGet(`https://you.dj/beatport/beatcms.php`, {action: `get`, source: project, type: `news`}, project);
  const [trackers] = useGet(`https://you.dj/beatport/trackingcms.php`, {source: project}, project);
  const [selectedRow, setRow] = useState(null);
  const [tab, setTab] = useState(0);
  const [isAdd, setStateAdd] = useState(false);

  const change = (id, name, type) => (e) => {
    let row = (type === `ads`) ? ads.find( x => x.id === id ) : news.find( x => x.id === id );
    row.data[name] = e.target.value;
    setRow({...row});
  }

  const handleTab = (event, newValue) => {
    setRow(null);
    setTab(newValue);
    setStateAdd(false);
  }

  const newsAdd = () => {
    setRow(null);
    setStateAdd(true);
  }

  const reload = (tab) => () => {
    if (tab === 0) { reloadAds() }
    if (tab === 1) { reloadNews() }
    handleTab(null, tab);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          {/*
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          */}
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>CMS</Typography>
          <div className={`project ${project === `beatport` && `selected`}`} onClick={changeProject(`beatport`)}>
            <svg className={`logo`} width="115" height="20" viewBox="0 0 115 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path  d="M6.16859 0C8.00823 0 9.43676 0.355953 10.4532 1.06695C11.4696 1.77794 11.9774 2.77096 11.9774 4.04417C11.9774 4.97787 11.7092 5.74454 11.1729 6.34418C10.6366 6.94382 9.86463 7.34815 8.85608 7.55533C11.3681 7.9551 12.6251 9.1772 12.6251 11.2216C12.6251 12.5697 12.0848 13.6294 11.0053 14.3997C9.92476 15.17 8.38483 15.5552 6.3845 15.5552H0V0H6.16859ZM2.75945 6.60065H6.23958C7.18404 6.60065 7.91556 6.40077 8.4361 6.001C8.95565 5.60124 9.21592 5.05271 9.21592 4.35632C9.21592 3.65993 8.95565 3.11596 8.4361 2.72259C7.91556 2.33013 7.18404 2.13389 6.23958 2.13389H2.75945V6.60065ZM2.75945 13.4003H6.40816C7.49657 13.4003 8.33258 13.1967 8.91622 12.7888C9.49986 12.3817 9.79266 11.8149 9.79266 11.0884C9.79266 10.3327 9.49591 9.74764 8.90439 9.33327C8.31188 8.91891 7.47981 8.71081 6.40816 8.71081H2.75945V13.4003Z" fill="#fff"/>
              <path  d="M14.0191 6.81156C14.5229 5.92989 15.2268 5.24902 16.1308 4.76711C17.0349 4.28612 18.071 4.04517 19.2393 4.04517C20.4233 4.04517 21.4674 4.26695 22.3714 4.71144C23.2755 5.15592 23.9873 5.78568 24.5078 6.59981C25.0274 7.41485 25.2955 8.37045 25.3123 9.4666C25.3123 9.76323 25.2886 10.0662 25.2403 10.3775H15.9751V10.5107C16.0391 11.5028 16.3753 12.2887 16.9836 12.8664C17.5919 13.4442 18.3993 13.7335 19.4079 13.7335C20.2074 13.7335 20.8798 13.5592 21.424 13.2114C21.9682 12.8637 22.328 12.3708 22.5045 11.7338H25.0964C24.8716 12.8892 24.2682 13.8375 23.2843 14.5777C22.3004 15.3188 21.072 15.6885 19.6001 15.6885C18.3195 15.6885 17.2035 15.4475 16.2521 14.9665C15.2997 14.4855 14.5633 13.8074 14.0437 12.933C13.5232 12.0587 13.2639 11.0438 13.2639 9.88827C13.2629 8.7191 13.5153 7.69323 14.0191 6.81156ZM22.6721 8.62236C22.5597 7.79271 22.2038 7.1447 21.6044 6.67831C21.004 6.21192 20.2478 5.97826 19.3359 5.97826C18.4871 5.97826 17.7555 6.21922 17.1394 6.70021C16.5232 7.18212 16.1673 7.82192 16.0717 8.62236H22.6721Z" fill="#fff"/>
              <path  d="M38.0872 13.4669V15.5561H36.7188C35.9183 15.5561 35.3426 15.4009 34.9906 15.0897C34.6386 14.7785 34.4622 14.3267 34.4622 13.7343C33.5335 15.0386 32.1819 15.6893 30.4053 15.6893C29.029 15.6893 27.9249 15.3936 27.0928 14.8004C26.2607 14.208 25.8447 13.393 25.8447 12.3562C25.8447 11.1861 26.2923 10.2862 27.1884 9.65639C28.0846 9.02663 29.3889 8.71174 31.1013 8.71174H34.198V8.02265C34.198 7.38559 33.9584 6.88634 33.4783 6.52309C32.9982 6.16075 32.3337 5.97912 31.4858 5.97912C30.7336 5.97912 30.1135 6.13154 29.6255 6.43456C29.1375 6.73849 28.8457 7.1419 28.7491 7.64571H26.2045C26.3327 6.50483 26.8769 5.62043 27.8371 4.99066C28.7974 4.3609 30.0534 4.04602 31.6061 4.04602C33.2545 4.04602 34.5263 4.40197 35.4224 5.11296C36.3186 5.82396 36.7662 6.84619 36.7662 8.17964V12.6683C36.7662 13.2013 37.0383 13.4678 37.5825 13.4678H38.0872V13.4669ZM34.1989 10.4888H30.982C30.1815 10.4888 29.5663 10.633 29.1335 10.9223C28.7017 11.2116 28.4858 11.637 28.4858 12.2001C28.4858 12.6893 28.6899 13.0818 29.098 13.3775C29.5062 13.6741 30.0622 13.822 30.7661 13.822C31.8378 13.822 32.6748 13.5554 33.2742 13.0224C33.8746 12.4894 34.1822 11.7775 34.198 10.8895V10.4888H34.1989Z" fill="#fff"/>
              <path  d="M39.215 6.28944H37.0786V4.17836H39.215V1.00034H41.8315V4.17836H44.7842V6.28944H41.8315V12.2229C41.8315 12.6674 41.9271 12.9823 42.1194 13.1675C42.3116 13.3528 42.6389 13.445 43.1032 13.445H45.071V15.5561H42.5748C41.4066 15.5561 40.5548 15.3042 40.0185 14.8004C39.4822 14.2966 39.214 13.5189 39.214 12.4675V6.28944H39.215Z" fill="#fff"/>
              <path  d="M55.5509 4.74521C56.4303 5.2116 57.1195 5.88608 57.6154 6.76775C58.1112 7.64942 58.3597 8.6826 58.3597 9.86728C58.3597 11.0228 58.1112 12.0413 57.6154 12.923C57.1195 13.8047 56.4313 14.4865 55.5509 14.9675C54.6706 15.4494 53.6709 15.6894 52.5509 15.6894C51.639 15.6894 50.8464 15.5342 50.175 15.223C49.5026 14.9118 48.9663 14.4892 48.567 13.9562V20.0001H45.9752V4.17842H48.2318L48.5443 5.86691C49.5361 4.65211 50.873 4.04517 52.5529 4.04517C53.6709 4.04517 54.6706 4.2779 55.5509 4.74521ZM54.7228 12.5999C55.3863 11.8889 55.7185 10.978 55.7185 9.86637C55.7185 8.74101 55.3863 7.82922 54.7228 7.13283C54.0583 6.43644 53.1908 6.0887 52.1181 6.0887C51.0455 6.0887 50.1819 6.43279 49.5263 7.12188C48.8697 7.81097 48.5424 8.71089 48.5424 9.82165C48.5424 10.9625 48.8707 11.888 49.5263 12.599C50.1819 13.31 51.0465 13.6659 52.1181 13.6659C53.1908 13.6669 54.0593 13.3109 54.7228 12.5999Z" fill="#fff"/>
              <path  d="M62.1404 14.9665C61.1881 14.4855 60.4516 13.8038 59.9321 12.9221C59.4115 12.0413 59.1522 11.0218 59.1522 9.86637C59.1522 8.72549 59.4125 7.71422 59.9321 6.83346C60.4516 5.9518 61.1881 5.26727 62.1404 4.77806C63.0928 4.28886 64.1842 4.04517 65.4165 4.04517C66.6489 4.04517 67.7412 4.28977 68.6926 4.77806C69.644 5.26727 70.3804 5.95271 70.901 6.83346C71.4205 7.71513 71.6808 8.72641 71.6808 9.86637C71.6808 11.0218 71.4205 12.0404 70.901 12.9221C70.3804 13.8038 69.6449 14.4855 68.6926 14.9665C67.7402 15.4484 66.6479 15.6885 65.4165 15.6885C64.1842 15.6885 63.0918 15.4484 62.1404 14.9665ZM68.0449 12.589C68.7084 11.8853 69.0406 10.978 69.0406 9.86637C69.0406 8.75561 68.7084 7.84839 68.0449 7.14378C67.3804 6.44009 66.5049 6.0887 65.4165 6.0887C64.3281 6.0887 63.4566 6.441 62.8 7.14378C62.1434 7.84747 61.8161 8.7547 61.8161 9.86637C61.8161 10.9771 62.1444 11.8853 62.8 12.589C63.4556 13.2926 64.3281 13.644 65.4165 13.644C66.5049 13.644 67.3804 13.2926 68.0449 12.589Z" fill="#fff"/>
              <path  d="M79.3617 4.17838V6.42271H78.2092C77.1366 6.42271 76.3607 6.74489 75.8806 7.38925C75.4005 8.03362 75.1609 8.85232 75.1609 9.84442V15.5552H72.5691V4.17838H74.873L75.1609 5.88969C75.5129 5.35667 75.9684 4.93774 76.5293 4.63381C77.0893 4.32988 77.8415 4.17838 78.786 4.17838H79.3617Z" fill="#fff"/>
              <path  d="M80.4186 1.00034H83.0105V4.17836H85.9868V6.28944H83.0105V12.2229C83.0105 12.6674 83.1061 12.9823 83.2983 13.1675C83.4906 13.3528 83.8179 13.445 84.2822 13.445H86.25V15.5561H83.7538C82.5856 15.5561 81.7377 15.3042 81.2093 14.8004C80.6809 14.2966 80.4176 13.5189 80.4176 12.4675V1.00034H80.4186Z" fill="#fff"/>
              <path  d="M91.4773 0V15.75H99.7637C99.7637 15.75 104.725 10.9184 106.97 8.69256C107.665 8.00686 108.05 7.07855 108.05 6.10802V0H91.4773ZM103.934 6.43504L98.1812 12.0156H95.2623V3.74497H104.244V5.68602C104.254 5.97085 104.137 6.2346 103.934 6.43504Z" fill="#1AE691"/>
              <path  d="M109.483 15.75L112.69 12.5958C114.219 11.0872 115 9.25167 115 7.07853V0H111.204V7.07853C111.204 8.1862 110.798 9.18839 110.017 9.95848L104.094 15.7394H109.483V15.75Z" fill="#1AE691"/>
            </svg>
          </div>
          <div className={`project ${project === `beatsource` && `selected`}`} onClick={changeProject(`beatsource`)}>
            <svg className={`logo`} xmlns="http://www.w3.org/2000/svg" width="115" height="20" viewBox="0 0 810.383 102.92">
              <g id="BeatsourceDJ_White_Blue" data-name="BeatsourceDJ_White+Blue" transform="translate(784.461 -860.08)">
                <g id="Group_26" data-name="Group 26" transform="translate(-102.854 874.035)">
                  <path  id="Path_32" data-name="Path 32" d="M722.33,380.96V468.4h45.347s27.308-26.807,39.585-39.083a20.534,20.534,0,0,0,6.013-14.281V381.211H722.33Zm68.146,35.827-31.568,31.066H742.874V402.005h49.1v10.773A4.572,4.572,0,0,1,790.476,416.787Z" transform="translate(-722.33 -380.96)" fill="#057edd"/>
                  <path  id="Path_33" data-name="Path 33" d="M779.644,468.4l17.538-17.537a41.778,41.778,0,0,0,12.527-30.565V380.96H788.914v39.334a22.121,22.121,0,0,1-6.514,16.034L749.83,468.4Z" transform="translate(-680.933 -380.96)" fill="#057edd"/>
                </g>
                <path  id="Path_34" data-name="Path 34" d="M488.928,478.135a29.041,29.041,0,0,1-21.371-8.969v7.491H450.27V375.39h18.089v37.831a29.187,29.187,0,0,1,20.544-8.167c18.389,0,33.346,15.784,33.346,36.6C522.249,462.351,507.267,478.135,488.928,478.135ZM486.3,421.664c-9.946,0-17.963,8.969-17.963,20.168,0,10.9,8.042,19.742,17.963,19.742,9.946,0,17.964-8.568,17.964-19.742C504.285,430.357,496.243,421.664,486.3,421.664Z" transform="translate(-1234.731 484.69)" fill="#fff"/>
                <path  id="Path_35" data-name="Path 35" d="M499.174,430.954c2.33,8.568,9.119,13.88,17.537,13.88,5.988,0,11.85-2.581,14.832-8.167h19.191c-4.084,14.281-16.485,23.951-33.9,23.951-19.742,0-35.927-16.335-35.927-36.6s16.185-36.9,35.927-36.9c20.669,0,37.831,16.059,35.25,43.844ZM534.3,416.272a17.584,17.584,0,0,0-17.537-13.354c-8.167,0-14.706,5.161-17.287,13.354Z" transform="translate(-1188.607 502.333)" fill="#fff"/>
                <path  id="Path_36" data-name="Path 36" d="M560.807,452.45c-4.61,4.911-11.7,7.892-21.521,7.892-14.832,0-28.436-7.491-28.436-22.749,0-15.784,13.2-19.868,28.711-21.22,11.7-1.1,19.993-2.455,19.993-8.167,0-3.683-4.76-6.664-12.8-6.664-6.389,0-13.729,2.856-15.383,8.844H513.405c1.779-13.354,14.581-23.275,32.945-23.275,28.436,0,30.615,18.389,30.615,23.951v36.478A43.49,43.49,0,0,0,579,458.964H562.51A25,25,0,0,1,560.807,452.45Zm-.551-27.208c-3.808,2.455-9.671,3.683-17.011,4.485-8.844.977-14.281,2.856-14.281,8.042,0,3.933,4.61,6.664,11.85,6.664,10.748,0,19.442-3.683,19.442-12.652Z" transform="translate(-1143.537 502.333)" fill="#fff"/>
                <path  id="Path_37" data-name="Path 37" d="M578.565,468.5c-20.544,2.18-31.442-10.222-31.442-29.388V414.462H538.68V398.528h8.443V380.69h17.963v17.838h12.652v15.934H565.086V439.24c0,9.8,4.485,13.479,13.479,12.928Z" transform="translate(-1101.643 492.668)" fill="#fff"/>
                <path  id="Path_38" data-name="Path 38" d="M589.181,460.617c-17.838,0-31.041-9.4-33.221-24.5H574.2c2.455,5.988,9.671,8.167,14.957,8.167,7.215,0,12.652-2.18,12.652-6.263,0-3.933-2.455-5.587-14.706-7.491-12.527-1.9-29.263-5.311-29.263-20.97,0-11.024,9.671-22.448,29.939-22.448,16.059,0,28.035,8.719,30.34,21.9h-18.64c-1.1-3.257-5.036-5.838-11.7-5.838-8.293,0-11.7,3.006-11.7,5.587,0,3.683,5.161,4.91,13.88,6.263,17.412,2.856,29.939,6.664,29.939,21.9C619.947,450.821,607.144,460.617,589.181,460.617Z" transform="translate(-1075.63 502.333)" fill="#fff"/>
                <path  id="Path_39" data-name="Path 39" d="M653.746,424.014c0,20.97-15.934,36.6-35.376,36.6s-35.25-15.633-35.25-36.6,15.809-36.9,35.25-36.9C637.812,387.135,653.746,403.044,653.746,424.014Zm-18.089.125c0-12.126-7.767-20.544-17.287-20.544s-17.287,8.443-17.287,20.544c0,11.85,7.742,19.993,17.287,19.993C627.891,444.157,635.657,435.99,635.657,424.139Z" transform="translate(-1034.745 502.333)" fill="#fff"/>
                <path  id="Path_40" data-name="Path 40" d="M676.374,457.915H659.212v-6.389a22.984,22.984,0,0,1-17.838,8.042c-16.61,0-27.634-12.376-27.634-30.766V387.84H631.7V428.8c0,3.933,1.353,13.729,13.053,13.729,8.042,0,13.6-5.988,13.6-13.729V387.84h17.963v70.1h.05Z" transform="translate(-988.651 503.432)" fill="#fff"/>
                <path  id="Path_41" data-name="Path 41" d="M682.025,404.891c-7.215-.8-13.2,0-17.287,4.359-3.808,3.933-4.484,9.671-4.484,15.258v34.449H642.29v-70.1h17.137v6.94c5.712-7.065,14.155-9.671,22.573-8.293v17.387Z" transform="translate(-945.673 502.39)" fill="#fff"/>
                <path  id="Path_42" data-name="Path 42" d="M694.393,460.617c-21.1,0-36.2-16.335-36.2-36.6s15.107-36.9,36.2-36.9c16.861,0,29.663,9.52,33.772,25.329H708.974a16.8,16.8,0,0,0-14.581-8.844c-9.946,0-17.964,9.245-17.964,20.544,0,11.023,8.042,20.168,17.964,20.168a16.56,16.56,0,0,0,14.706-8.969H728.29C724.181,451.222,711.379,460.617,694.393,460.617Z" transform="translate(-921.738 502.333)" fill="#fff"/>
                <path  id="Path_43" data-name="Path 43" d="M705.844,430.954c2.33,8.568,9.12,13.88,17.537,13.88,5.988,0,11.85-2.581,14.832-8.167H757.4c-4.084,14.281-16.485,23.951-33.9,23.951-19.742,0-35.927-16.335-35.927-36.6s16.185-36.9,35.927-36.9c20.669,0,37.831,16.059,35.25,43.844Zm35.125-14.681a17.584,17.584,0,0,0-17.537-13.354c-8.167,0-14.706,5.161-17.287,13.354Z" transform="translate(-877.495 502.333)" fill="#fff"/>
              </g>
            </svg>
          </div>


        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={(tab === 0) ? 8 : 12} lg={(tab === 0) ? 9 : 12}>
              <Paper className={fixedHeightPaper}>
                <Tabs value={tab} onChange={handleTab} >
                  <Tab label="Last Ads" id={`simple-tab-0`} aria-controls={`simple-tabpanel-0`} />
                  <Tab label="Last News" id={`simple-tab-1`} aria-controls={`simple-tabpanel-1`} />
                  <Tab label="Tracking" id={`simple-tab-2`} aria-controls={`simple-tabpanel-2`} />
                  {tab === 1 && <Button variant="contained" color="primary" className={classes.buttonNew} onClick={newsAdd}>New</Button> }
                </Tabs>
                { tab === 0 &&  <Ads rows={ads} setRow={setRow} /> }
                { (tab === 1 && !isAdd) && <News source={project} rows={news} setRow={setRow} onReload={reload(1)} /> }
                { tab === 2 &&  <Tracking data={trackers} /> }
                <Paper elevation={3} className={classes.editAd} >
                  { (selectedRow && selectedRow.type === 'ads') && <AdEdit source={project} ad={selectedRow} onChange={change} onReload={reload(0)} /> }
                  { (selectedRow && selectedRow.type === 'news') && <NewsEdit source={project} news={selectedRow} onChange={change} onReload={reload(1)} /> }
                  { (!selectedRow && isAdd) && <NewsAdd source={project} onReload={reload(1)} /> }
                </Paper>
              </Paper>
            </Grid>
            {tab === 0 && (
              <Grid item xs={12} md={4} lg={3}>
                <Paper className={fixedHeightPaper}>
                  <PreviewAd ad={selectedRow} />
                </Paper>
              </Grid>
            )}

            {/*
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Orders />
              </Paper>
            </Grid>
            */}
          </Grid>
        </Container>
      </main>
    </div>
  );
}
