import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
const axios = require('axios');
const querystring = require('querystring');

const useStyles = makeStyles((theme) => ({
  form: { height: 190, margin: 10, position: `relative` },
  field: { width: `100%` },
  button: { bottom: 0, right: 0, position: `absolute` }
}));

export default function NewsEdit({ source, news = null, onChange, onReload }) {
  const classes = useStyles();

  const publish = () => {
    if (news) {
      const options = { action: `update`, source, type: `news`, uid: news.uid, data: JSON.stringify(news.data) };
      const request = axios.post(`https://you.dj/beatport/beatcms.php`, querystring.stringify(options), { headers: { 'content-type': 'application/x-www-form-urlencoded; charset=utf-8' } });
      request.then( response => {
        onReload();
      } ).catch( e => { console.log(e) });
    }
  }

  return news ? (
    <form noValidate className={classes.form} >
      <TextField multiline rows={6} id="filled-basic" label="Content" variant="filled" className={classes.field} value={news.data.content} onChange={onChange(news.id, `content`, `news`)} />
      <Button variant="contained" className={classes.button} color="primary" onClick={publish}>Update</Button>
    </form>
  ) : null;
}

NewsEdit.propTypes = {
  source: PropTypes.string.isRequired,
  news: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
}
