import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
const axios = require('axios');
const querystring = require('querystring');

const useStyles = makeStyles((theme) => ({
  form: { height: 190, margin: 10, position: `relative` },
  field: { width: `100%` },
  button: { bottom: 0, right: 0, position: `absolute` }
}));

export default function NewsAdd({ source, onReload }) {
  const classes = useStyles();
  const [content, setContent] = useState(``);

  const change = (e) => { setContent(e.target.value) }
  const create = () => {
    const options = { action: `add`, source, type: `news`, data: JSON.stringify({ content }) };
    const request = axios.post(`https://you.dj/beatport/beatcms.php`, querystring.stringify(options), { headers: { 'content-type': 'application/x-www-form-urlencoded; charset=utf-8' } });
    request.then( response => {
      onReload();
    }).catch( e => { console.log(e) });
  }

  return (
    <form noValidate className={classes.form} >
      <TextField multiline rows={6} id="filled-basic" label="Content" variant="filled" className={classes.field} value={content} onChange={change} />
      <Button variant="contained" className={classes.button} color="primary" onClick={create}>Create</Button>
    </form>
  );
}

NewsAdd.propTypes = {
  source: PropTypes.string.isRequired,
  onReload: PropTypes.func.isRequired
}
