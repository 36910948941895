import React from 'react';
import PropTypes from 'prop-types';
import Title from './Title';

Tracking.propTypes = {
  data: PropTypes.array.isRequired
}

export default function Tracking({ data = [] }) {
  const trackerKeys = (data.length > 0) ? Object.keys(data[0].trackers) : [];
  let trackers = {};
  trackerKeys.forEach( key => { trackers[key] = 0 } );

  data.forEach( user => {
    trackerKeys.forEach( key => {
      if (user.trackers[key]) {
        trackers[key] += 1;
      }
    });
  })

  return (
    <React.Fragment>
      <Title>Total Tracking Users: {data.length}</Title>
      { trackerKeys.map( key => <span>Click on {key}: {trackers[key]}</span>) }
    </React.Fragment>
  );
}
