import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Title from './Title';

const useStyles = makeStyles({
  preview: {
    background: `rgb(26, 26, 26)`,
    width: 240,
    height: 240,
    border: `1px solid`
  },
  adContainer: {
    // padding: 16
  },
  adImage: {
    width: `100%`,
    // borderRadius: 4
  },
  adClose: {
    position: `absolute`,
    margin: 4,
    cursor: `pointer`
  }
});

export default function PreviewAd({ ad = null }) {
  const classes = useStyles();
  return (
    <>
      <Title>{ad ? `Preview Ad: ${ad.data.name}` : `No Preview Ad`}</Title>
      <div className={classes.preview}>
        {ad && (
          <div className={classes.adContainer}>
            <svg className={classes.adClose} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.6" width="20" height="20" rx="10" fill="#242424"/>
              <path d="M13.5 7.205L12.795 6.5L10 9.295L7.205 6.5L6.5 7.205L9.295 10L6.5 12.795L7.205 13.5L10 10.705L12.795 13.5L13.5 12.795L10.705 10L13.5 7.205Z" fill="#FAFAFA"/>
            </svg>
            <a href={ad.data.url} target={`_blank`}><img alt={`preview-ad`} src={ad.data.src} className={classes.adImage} /></a>
          </div>
        )}
      </div>
    </>
  );
}
