import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
const axios = require('axios');
const querystring = require('querystring');

const useStyles = makeStyles((theme) => ({
  form: { height: 210, margin: 10, position: `relative` },
  field: { width: `100%` },
  button: { bottom: 0, right: 0, position: `absolute` }
}));

export default function AdEdit({ source, ad = null, onChange, onReload }) {
  const classes = useStyles();

  const publish = () => {
    if (ad) {
      const options = { action: `update`, source, type: `ads`, uid: ad.uid, data: JSON.stringify(ad.data) };
      const request = axios.post(`https://you.dj/beatport/beatcms.php`, querystring.stringify(options), { headers: { 'content-type': 'application/x-www-form-urlencoded; charset=utf-8' } });
      request.then( response => {
        // window.location.reload(true);
        onReload();
      }).catch( e => { console.log(e) });
    }
  }

  return ad ? (
    <form noValidate className={classes.form} >
      <TextField id="filled-basic" label="Name" variant="filled" className={classes.field} value={ad.data.name} onChange={onChange(ad.id, `name`, `ads`)} />
      <TextField id="filled-basic" label="URL Image" variant="filled" className={classes.field} value={ad.data.src} onChange={onChange(ad.id, `src`, `ads`)} />
      <TextField id="filled-basic" label="Link Redirection" variant="filled" className={classes.field} value={ad.data.url} onChange={onChange(ad.id, `url`, `ads`)} />
      <Button variant="contained" className={classes.button} color="primary" onClick={publish}>Publish</Button>
    </form>
  ) : null;
}

AdEdit.propTypes = {
  source: PropTypes.string.isRequired,
  ad: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
}
