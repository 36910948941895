import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Title from './Title';

const useStyles = makeStyles((theme) => ({
  table: { marginTop: theme.spacing(3) },
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

Ads.propTypes = {
  rows: PropTypes.array.isRequired,
  setRow: PropTypes.func.isRequired
}

export default function Ads({ rows, setRow }) {
  const classes = useStyles();
  const [indexSelected, setIndexSelected] = useState(null);
  const editRow = (row, index) => () => {
    setRow(row);
    setIndexSelected(index);
  };
  return (
    <React.Fragment>
      { /* <Title>Last Recent Ads</Title> */ }
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Last Published</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>UID</TableCell>
            {/* <TableCell align="right">Preview / Edit</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={row.uid} selected={indexSelected === i} onClick={editRow(row, i)}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.data.name}</TableCell>
              <TableCell>{row.uid}</TableCell>
              {/* <TableCell align="right" ></TableCell> */ }
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/*
      <div className={classes.seeMore}>
        <Link color="primary" href="#" onClick={preventDefault}>
          See more orders
        </Link>
      </div>
      */}
    </React.Fragment>
  );
}
