import { useState, useCallback, useEffect } from "react";
const axios = require('axios');
const querystring = require('querystring');

const useGet = (path, options, source) => {
  const [data, setData] = useState([]);

  const loadRequest = useCallback(() => {
    const request = axios.post(path, querystring.stringify(options), { headers: { 'content-type': 'application/x-www-form-urlencoded; charset=utf-8' } });
    request.then( response => {
      setData(response.data);
    }).catch( e => { console.log(e) });
  }, [path, options])

  useEffect(() => {
    loadRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  const reloadHook = () => { loadRequest() }

  return [data, reloadHook];
};

export default useGet;
